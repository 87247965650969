import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { contactService, result } from 'src/app/services/contactService';
import { AlertifyService } from "src/app/alertify.service";
import { SocialService } from 'src/app/services/socailService';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    contactForm!: FormGroup;
    contact!: ContactModel;
    languageId: number = 1;
    key: any;
    tel: any;
    mail: any;
    adres: any;

    constructor(private contactservice: contactService, private formBuilder: FormBuilder, private alertify: AlertifyService, private socialService: SocialService) { }
    ngOnInit(): void {
        this.getSocial();
        this.createForm();
    }

    createForm() {
        this.contactForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            subject: ['', Validators.required],
            message: ['', Validators.required],
            recaptcha: ['', Validators.required]
        });
    }
    
    message!: result;
    sendContact() {
        if (this.contactForm.valid) {
            this.alertify.warning("Gönderiliyor...")
            this.contactservice.sendContact(this.contactForm.value).subscribe((resp) => {
                this.message = resp;
                if (this.message.success) {
                    this.alertify.success(this.message.message);
                    this.contactForm.reset();
                } else {
                    this.alertify.error(this.message.message);
                }
            });
        } else {
            this.alertify.error("Lütfen formu doldurun");
        }
    }


    getSocial() {
        this.socialService.getSocial(this.languageId, this.key = "Tel").subscribe((gelenCevap: any) => {
            this.tel = gelenCevap
        })
        this.socialService.getSocial(this.languageId, this.key = "Mail").subscribe((gelenCevap: any) => {
            this.mail = gelenCevap
        })
        this.socialService.getSocial(this.languageId, this.key = "Adres").subscribe((gelenCevap: any) => {
            this.adres = gelenCevap
        })
    }
    
}

export interface ContactModel {
    firstName: string;
    lastName: string;
    email: string;
    subject: string;
    message: string;
    recaptcha:string;
}
