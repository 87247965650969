import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class CertificateService {
  path = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getCertificate(){
    const url=`FaqAPI/GetList`;
    return this.http.get(this.path+url);
  }
}