import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StickyNavDirective } from "ng2-sticky-nav";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class SocialService {
  path = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getSocial(languageId: any, key: string): Observable<SiteSettingModel> {
    if (languageId == null) {
      languageId = 1
    }
    const url = `SiteSettingAPI/GetByKey?key=${key}&languageId=${languageId}`;
    return this.http.get<SiteSettingModel>(this.path + url);
  }
}

export interface SiteSettingModel {
  key: string;
  value: string;
  languageID: number;
  languageName: string;
}