<div class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngIf="pageDetail">
            <h2>{{pageDetail.title}}</h2>
            <ul>
                <li><a routerLink="/">Anasayfa </a></li>
                <li class="active">{{pageDetail.title}}</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area bg-color"><!--Hakkımızda -->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img" *ngIf="pageDetail">
                    <img src="assets/images/company/comp.jpg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="margin-top: 1rem;">
                    <div class="company-tittle" *ngIf="pageDetail">
                        <!-- <span>Our Company</span> -->
                        <h2>{{pageDetail.preContent}}</h2>
                        <p innerHTML="{{pageDetail.content}}"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area bg-color"><!--kURUMSAL -->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6">
                <div class="company-tittle">
                    <link rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
                        integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=="
                        crossorigin="anonymous" />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                        rel="stylesheet">
                        <div class="card app-file-list">
                            <div class="app-file-icon">
                                <img src="assets/kurumsal/kkk.png" alt="shape" />
                            </div>
                            <div class="description">
                                <div>
                                    <div><h3>Kurumsal Kimlik</h3></div>
                                </div>
                                <div>
                                    <a href="assets/kurumsal/kk.pdf" target="_blank"><i class="fa fa-book"></i></a>
                                </div>
                            </div>
                        </div> 
                </div>
            </div>
            <div class="col-lg-3 col-md-6" style="text-align: center;">
                <div class="company-tittle"  style="text-align: center;">
                    <link rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
                        integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=="
                        crossorigin="anonymous" />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                        rel="stylesheet">
                        <div class="card app-file-list">
                            <div class="app-file-icon">
                                <img src="assets/kurumsal/drg.png" alt="shape" />
                            </div>
                            <div class="description">
                                <div>
                                    <div><h3>Sunum Dosyası</h3></div>
                                </div>
                                <div>
                                    <a href="assets/kurumsal/dergi.pdf" target="_blank"><i class="fa fa-book"></i></a>
                                </div>
                            </div>
                        </div> 
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="row align-items-center" style="margin-top: 1rem;">
                    <div class="col-lg-6 col-md-12">
                        <div class="company-tittle">
                            <img src="assets/kurumsal/27.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row align-items-center" style="margin-top: 1rem;">
                    <div class="col-lg-6 col-md-12">
                        <h3>Kurumsal belgelerini indirmek için <a href="assets/kurumsal/kurumsal.zip">tıklayınız</a>(.ZIP)</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <style>
        body {
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: 'Roboto';
}
.card {
  margin-bottom: 1.875rem;
  width: 250px;
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  border: none;
  margin: auto;
  margin-top: 1rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.app-file-list {
  border: 1px solid #ebebeb;
}

.app-file-list .app-file-icon {
  background-color: #F5F5F5;
  padding: 2rem;
  text-align: center;
  font-size: 2rem;
  border-bottom: 1px solid #ebebeb;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.app-file-list:hover {
  border-color: #d7d7d7;
}

.description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.fa-download {
  color: #333333;
  cursor: pointer;
  transition: 0.3s
}

.fa-download:hover {
  color: #2ec1ac;
}

.fa-download:active {
  color: #28df99;
}

.credits {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.credits a {
  color: #222222;
  text-decoration: none;
  font-weight: 800;
}
    </style>
</section>
