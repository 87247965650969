import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from 'src/app/services/pageService'
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  pageDetail:any;

  constructor(private http:HttpClient,private pageService:PageService,
    private activatedRoute: ActivatedRoute) { 
      this.activatedRoute.params.subscribe(param=>{
        this.getPage(param.pageId)
      }
      )
    }

  ngOnInit(): void {
  }

  getPage(id:string){
    this.pageService.getPagesDetail(id).subscribe(resp=>{
      this.pageDetail=resp;
    })
  }

}