import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ProjectService {
  path = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getProject(departmentId:number){
    const url =`ProjectAPI/GetList?depId=${departmentId}`;
    return this.http.get(this.path+url);
  }

  getProjectNonFilter(): Observable<ProjectModel[]>{
    const url =`ProjectAPI/GetListNonFilter`;
    return this.http.get<ProjectModel[]>(this.path + url);
  }

}

export interface ProjectModel {

  id:number;
  departmentId:number;
  title:string;
  detail:string;
  image:string;
  order:number;
  isActive:boolean;
  departmentName:string;
}