<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Sertifikalarımız</h2>
            <ul>
                <li><a routerLink="/">Anasayfa </a></li>
                <li class="active">Sertifikalarımız</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sertikalarımız</h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <accordion >
                            <accordion-group *ngFor="let certificate of certificates" [isOpened]="false" heading="{{certificate.question}}">
                                <p innerHTML="{{certificate.answer}}"></p>
                            </accordion-group>                           
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
