import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FrontMenuItemModel, NavbarService } from 'src/app/services/navbarService';
import { HttpClient } from '@angular/common/http';
import { SiteSettingModel, SocialService } from 'src/app/services/socailService';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class NavbarComponent implements OnInit {

  languageId: number = 1;
  key: string;


  location: any;
  navbarClass: any;
  menus: FrontMenuItemModel[] = [];

  tel: SiteSettingModel;
  adres: SiteSettingModel;
  mail: SiteSettingModel;
  facebook: SiteSettingModel;
  instagram: SiteSettingModel;
  twitter: SiteSettingModel;
  youtube: SiteSettingModel;
  linkedin: SiteSettingModel;

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  constructor(
    private http: HttpClient,
    private navbarService: NavbarService,
    private router: Router,
    private socialService: SocialService,
    location: Location
  ) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.location = this.router.url;
          if (this.location == '/home-three') {
            this.navbarClass = 'navbar-area three';
          } else {
            this.navbarClass = 'navbar-area';
          }
        }
      });
  }

  ngOnInit(): void {

    this.navbarService.getMenuByPosition("1").subscribe((gelenCevap: any) => {
      this.menus = gelenCevap
    })

    this.socialService.getSocial(this.languageId, this.key = "Tel").subscribe((gelenCevap: any) => {
      this.tel = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Mail").subscribe((gelenCevap: any) => {
      this.mail = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Adres").subscribe((gelenCevap: any) => {
      this.adres = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "LinkedIn").subscribe((gelenCevap: any) => {
      this.linkedin = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Youtube").subscribe((gelenCevap: any) => {
      this.youtube = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Twitter").subscribe((gelenCevap: any) => {
      this.twitter = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Instagram").subscribe((gelenCevap: any) => {
      this.instagram = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Facebook").subscribe((gelenCevap: any) => {
      this.facebook = gelenCevap
    })

    
  }

}