import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ikModel } from "../components/pages/pricing/pricing.component";

@Injectable({
  providedIn: 'root'
})
export class ikService {
  path = environment.apiUrl;
  url = `CareerAPI/Add`;
  constructor(private http: HttpClient) { }

  sendik(ik: ikModel): Observable<result> {
    return this.http.post<result>(this.path + this.url, ik);
  }

}

export interface result {
  success: boolean;
  message: string;
}