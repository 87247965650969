import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class NavbarService {
  path = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getMenus(): Observable<FrontMenuItemModel[]> {
    const url = `FrontMenuItemAPI/GetList`;
    return this.http.get<FrontMenuItemModel[]>(this.path + url);
  }

  getMenuByPosition(id: string): Observable<FrontMenuItemModel[]> {
    const url = `FrontMenuItemAPI/GetListByPosition?position=`
    return this.http.get<FrontMenuItemModel[]>(this.path + url + id)
  }

  getMenuByslug(slug: string): Observable<FrontMenuItemModel> {
    const url = `FrontMenuItemAPI/GetByslug?slug=`
    return this.http.get<FrontMenuItemModel>(this.path + url + slug)
  }
}

export interface FrontMenuItemModel {
  id: number;
  parentId: number;
  languageId: number;
  pageId: number;
  slug: string;
  title: string;
  position: number;
  link: string;
  order: number;
  languageName: string;
  pageTitle: string;
  parentName: string;
}