import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class SolutionPartnerService {
  path = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getPartner(){
    const url=`GalleryAPI/GetList`;
    return this.http.get(this.path+url);
  }

}