<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logofooter">
                        <a routerLink="/"><img src="assets/images/logo2.png" alt="logo" style="max-width: 80%;" /></a>
                    </div>
                    <p *ngIf="logoAlti">{{logoAlti.value}}</p>

                    <ul class="social">
                        <li *ngIf="facebook"><a href="{{facebook.value}}" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li *ngIf="linkedin"><a href="{{linkedin.value}}" target="_blank"><i
                                    class='bx bxl-linkedin'></i></a></li>
                        <li *ngIf="youtube"><a href="{{youtube.value}}" target="_blank"><i
                                    class='bx bxl-youtube'></i></a></li>
                        <li *ngIf="twitter"><a href="{{twitter.value}}" target="_blank"><i
                                    class='bx bxl-twitter'></i></a></li>
                        <li *ngIf="instagram"><a href="{{instagram.value}}" target="_blank"><i
                                    class='bx bxl-instagram'></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-pinterest' ></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Hizmetlerimiz</h3>

                    <ul class="footer-list" *ngIf="departments">
                        <li *ngFor="let department of departments"><a
                                routerLink="/page/{{department.slug}}/{{department.order}}">{{department.name}}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Keşfet</h3>

                    <ul class="footer-list" *ngIf="menus">
                        <li *ngFor="let item of menus"><a routerLink="{{item.link}}">{{item.title}}</a></li>
                        <!-- <li><a routerLink="/kariyer">Kariyer</a></li>
                        <li><a routerLink="/gizlilik">Gizlilik Politikamız</a></li>
                        <li><a routerLink="/sartlar-kosullar">Şartlar & Koşullar</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">İletişim Bilgileri</h3>

                    <ul class="footer-list foot-social">
                        <li *ngIf="tel"><a href="tel:{{tel.value}}"><i class="bx bx-mobile-alt"></i>{{tel.value}}</a>
                        </li>
                        <li *ngIf="mail"><a href="mailto:{{mail.value}}"><i
                                    class="bx bxs-envelope"></i>{{mail.value}}</a></li>
                        <li *ngIf="adres"><a href="https://maps.google.com/maps?q={{adres.value}}"><i
                                    class="bx bxs-map"></i>{{adres.value}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12" style="text-align: center;" *ngIf="footerText">
                    <p>{{footerText.value}}<a href="https://vakifglobal.com/"
                            target="_blank"></a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>
