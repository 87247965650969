import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DepartmentService } from 'src/app/services/departmentService';
import { NavbarService } from 'src/app/services/navbarService';
import { __param } from 'tslib';
import { environment } from "src/environments/environment";
import { SolutionPartnerService } from 'src/app/services/solutionpartnerService';
import { PageModel, PageService } from 'src/app/services/pageService';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
    menus: any;
    baseUrl = environment.baseUrl;
    departments: any;
    partners: any;
    hakkimizda: PageModel;

    constructor(private http: HttpClient, private pageService: PageService, private navbarService: NavbarService, private router: Router, private solutionPartnerService: SolutionPartnerService,

        private activatedRoute: ActivatedRoute, private departmentService: DepartmentService) { }

    ngOnInit(): void {
        this.departmentService.getDepartments().subscribe((gelenCevap1: any) => {
            this.departments = gelenCevap1
        })

        this.solutionPartnerService.getPartner().subscribe((gelenCevap2: any) => {
            this.partners = gelenCevap2
        })
        this.pageService.getPageDetailByslug("hakkimizda").subscribe((gelenCevap3: PageModel) => {
            this.hakkimizda = gelenCevap3
        })

    }


    teamSlides: OwlOptions = {
        loop: true,
        margin: 20,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    }
    clientWrap: OwlOptions = {
        loop: true,
        margin: 30,
        nav: false,
        mouseDrag: true,
        items: 1,
        dots: false,
        autoHeight: true,
        autoplay: true,
        smartSpeed: 800,
        autoplayHoverPause: true,
        center: false,
        responsive: {
            0: {
                items: 1,
                margin: 10,
            },
            576: {
                items: 1,
            },
            768: {
                items: 2,
                margin: 20,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        }
    }

}