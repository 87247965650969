import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from 'src/app/services/pageService';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

  pageDetail:any;

  constructor(private pageService:PageService, private activatedRoute: ActivatedRoute) 
  {
    this.activatedRoute.params.subscribe(param=>{
      this.getPage(param.pageId)
    }
    )
   }

  ngOnInit(): void {
  }

  getPage(id:string){
    this.pageService.getPagesDetail(id).subscribe(resp=>{
      this.pageDetail=resp;
    })
  }

}
