import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-studies-details',
  templateUrl: './case-studies-details.component.html',
  styleUrls: ['./case-studies-details.component.scss']
})
export class CaseStudiesDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
