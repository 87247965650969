import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PageModel, PageService } from 'src/app/services/pageService';
import { ProjectModel, ProjectService } from 'src/app/services/projectService';
import { SolutionPartnerService } from 'src/app/services/solutionpartnerService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-solutions-details',
  templateUrl: './solutions-details.component.html',
  styleUrls: ['./solutions-details.component.scss']
})

export class SolutionsDetailsComponent implements OnInit {

  pageDetail: PageModel;
  items: ProjectModel[] = [];
  baseUrl = environment.baseUrl;
  partners: any;
  departmentId:any;
  parentId:PageModel;

  constructor(private http: HttpClient, private pageService: PageService,
    private solutionPartnerService: SolutionPartnerService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(param => {
      this.getPage(param.pageId)
    }
    )
  }

  ngOnInit(): void {
    this.solutionPartnerService.getPartner().subscribe((gelenCevap2: any) => {
      this.partners = gelenCevap2
    })
   
  }

  getPage(id: string) {
    this.pageService.getPagesDetail(id).subscribe(resp => {
      this.pageDetail = resp;
      this.getProject(this.pageDetail.parentId);
    })
  }
  getProject(departmentId: number) {
    this.projectService.getProject(departmentId).subscribe((gelenCevap: any) => {
      this.items = gelenCevap
    })
    
  }
  teamSlides: OwlOptions = {
    loop: true,
    margin: 20,
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      575: {
        items: 2,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 4,
      }
    }
  }
  clientWrap: OwlOptions = {
    loop: true,
    margin: 30,
    nav: false,
    mouseDrag: true,
    items: 1,
    dots: false,
    autoHeight: true,
    autoplay: true,
    smartSpeed: 800,
    autoplayHoverPause: true,
    center: false,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
        margin: 20,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2,
      }
    }
  }
}
