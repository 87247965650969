import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ProjectModel } from "./projectService";

@Injectable({
  providedIn: 'root'
})

export class DepartmentService {
  path = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getDepartments(): Observable<DepartmentModel>{
    const url=`DepartmentAPI/GetList`;
    return this.http.get<DepartmentModel>(this.path+url);
  }
}

export interface DepartmentModel{
  id:number;
  order:number;
  slug:string;
  name:string;
  description:string;
  image:string;
  isActive:boolean;
  projects:ProjectModel[];
}