import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { SolutionsComponent } from './components/pages/solutions/solutions.component';
import { SolutionsDetailsComponent } from './components/pages/solutions-details/solutions-details.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { CaseStudiesDetailsComponent } from './components/pages/case-studies-details/case-studies-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'hakkimizda/:pageId', component: AboutComponent},
    {path: 'kurumsal/:pageId', component: SolutionsComponent},
    {path: 'page/:slug/:pageId', component: SolutionsDetailsComponent},
    {path: 'projelerimiz/:pageId', component: CaseStudiesComponent},
    {path: 'page/proje-detayi/:pageId', component: CaseStudiesDetailsComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'kurumsal/yonetim/:pageId', component: TeamComponent},
    {path: 'kariyer/:pageId', component: PricingComponent},
    {path: 'kurumsal/personeller/:pageId', component: TestimonialsComponent},
    {path: 'hata', component: ErrorComponent},
    {path: 'kurumsal/sertifikalar/:pageId', component: FaqComponent},
    {path: 'iletisim/:pageId', component: ContactComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }