<div class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngIf="pageDetail">
            <h2>{{pageDetail.title}}</h2>
            <ul>
                <li><a routerLink="/">Anasayfa </a></li>
                <li class="active">{{pageDetail.title}}</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area bg-color"><!--Hakkımızda -->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle" *ngIf="pageDetail">
                        <h2 innerHTML="{{pageDetail.preContent}}"></h2>
                        <p innerHTML="{{pageDetail.content}}"></p> 
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img" *ngIf="pageDetail">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

