<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Kariyer</h2>
            <ul>
                <li><a routerLink="/">Anasayfa </a></li>
                <li class="active">Kariyer</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <head><link rel="preconnect" href="https://fonts.gstatic.com">
                <link href="https://fonts.googleapis.com/css2?family=Spartan:wght@100;300;500&display=swap" rel="stylesheet">
                </head>
                <div class="container">
                        <header class="header">
                            <h1 id="title" class="text-center">İş Başvuru Formu</h1>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="content">
                                        <form [formGroup]="ikForm" id="ikForm" (ngSubmit)="sendik()">
                                            <div class="row">
                                                <div class="col-lg-12 col-sm-12">
                                                    <h5>Adınız Soyadınız</h5>
                                                    <div class="form-group">
                                                        <input type="text" name="fullName" id="fullName" class="form-control"  formControlName="fullName" required placeholder="Ad Soyad" />
                                                    </div>
                                                </div>
                    
                                                <div class="col-lg-12 col-sm-12">
                                                    <h5>E-Posta Adresiniz </h5>
                                                    <div class="form-group">
                                                        <input type="email" name="email" id="email" class="form-control"  formControlName="email" required placeholder="E-Posta Adresiniz" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-sm-12">
                                                    <h5>Telefon Numaranız</h5>
                                                    <div class="form-group">
                                                        <input type="text" name="phone" id="phone" class="form-control"  formControlName="phone" required placeholder="Telefon Numarası" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-sm-12">
                                                    <h5>Doğum Tarihiniz</h5>
                                                    <div class="form-group">
                                                        <input type="date" name="birthDate" id="birthDate" class="form-control"  formControlName="birthDate" required placeholder="Doğum Tarihi" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-sm-12">
                                                    <h5>Özgeçmiş Dosyanız</h5>
                                                    <div class="form-group">
                                                        <input (change)="this.fileChangeEvent($event)" type="file" accept=".pdf" name="cvFile" id="cvFile" class="form-control"  formControlName="cvFile" required placeholder="CV Dosyanız (PDF)" />
                                                    </div>
                                                </div>
                    
                                                <div class="col-lg-12 col-md-12">
                                                    <h5>Hakkınızda</h5>
                                                    <div class="form-group">
                                                        <textarea name="description" class="form-control" id="description"  formControlName="description" cols="30" rows="3" required placeholder="Biraz Kendinizden Bahsedin. (LinkedIn, GitHub linki paylaşılabilir) "></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <re-captcha
                                                          siteKey="6LfzGCIkAAAAAJ0UIDIpnDqwckbJbxVLhdls2mtX"
                                                          formControlName="recaptcha"
                                                        ></re-captcha>
                                                      </div>
                                                </div>
                    
                                                <div class="col-lg-6 col-md-6">
                                                    <button type="submit" class="default-btn page-btn box-btn">Gönder</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </header>                          
                    </div>
                 </div>
            </div>
</section>
