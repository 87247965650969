import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class PageService {
  path = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getPagesDetail(pageId: string): Observable<PageModel> {
    const url = `PageAPI/GetById?id=${pageId}`;
    return this.http.get<PageModel>(this.path + url);
  }

  getPageDetailByslug(slug: string): Observable<PageModel> {
    const url = `PageAPI/GetBySlug?slug=${slug}`
    return this.http.get<PageModel>(this.path + url)
  }
}

export interface PageModel {
  id: number;
  slug: string;
  parentId: number;
  title: string;
  preContent: string;
  content: string;
  contentImage: string;
  bannerImage: string;
  parentName: string;
}