<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li *ngIf="tel"><a href="tel:{{tel.value}}"><i class="bx bx-mobile-alt"></i>{{tel.value}}</a>
                        </li>
                        <li *ngIf="mail"><a href="mailto:{{mail.value}}"><i
                                    class="bx bxs-envelope"></i>{{mail.value}}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li *ngIf="facebook"><a href="{{facebook.value}}" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li *ngIf="linkedin"><a href="{{linkedin.value}}" target="_blank"><i
                                    class='bx bxl-linkedin'></i></a></li>
                        <li *ngIf="youtube"><a href="{{youtube.value}}" target="_blank"><i
                                    class='bx bxl-youtube'></i></a></li>
                        <li *ngIf="twitter"><a href="{{twitter.value}}" target="_blank"><i
                                    class='bx bxl-twitter'></i></a></li>
                        <li *ngIf="instagram"><a href="{{instagram.value}}" target="_blank"><i
                                    class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div *ngIf="navbarClass" class="{{navbarClass}}">
    <div class="main-nav" ngStickyNav stickyClass="sticky-box-shadow">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="assets/images/logo2.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left" *ngIf="menus">
                        <li class="nav-item" *ngFor="let item of menus">
                            <a href="{{item.link}}" (click)="toggleClass()"
                                *ngIf="item.parentId == null && item.inverseParent.length == 0">{{item.title}}</a>

                            <a href="{{item.link}}" class="nav-link dropdown-toggle" (click)="toggleClass()"
                                *ngIf="item.inverseParent.length > 0">{{item.title}}</a>

                            <ul class="dropdown-menu" *ngIf="item.inverseParent.length > 0">

                                <li class="nav-item" *ngFor="let item2 of item.inverseParent">
                                    <a *ngIf="item2.pageId" routerLink="{{item2.link}}/{{item2.pageId}}" (click)="toggleClass()"
                                        class="nav-link">{{item2.title}}</a>
                                    <!-- <a *ngIf="!item2.pageId" routerLink="{{item2.link}}"
                                        class="nav-link">{{item2.title}}</a> -->
                                    <a *ngIf="!item2.pageId" href="{{item2.link}}" (click)="toggleClass()" class="nav-link">{{item2.title}}</a>
                                </li>
                            </ul>
                        </li>

                        <!-- 
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Anasayfa</a></li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/kurumsal" class="nav-link dropdown-toggle">Kurumsal</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/yonetim-ekibi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Yönetim Ekibimiz</a></li>
                                <li class="nav-item"><a routerLink="/personeller" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Personellerimiz</a></li>
                                <li class="nav-item"><a routerLink="/sertifikalar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sertifikalar</a></li>    
                            </ul>       
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Departmanlar</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/departman-detayi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Bilişim / Yazılım</a></li>
                                <li class="nav-item"><a routerLink="/departman-detayi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Eğitim</a></li>
                                <li class="nav-item"><a routerLink="/departman-detayi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Ajans</a></li>
                                <li class="nav-item"><a routerLink="/departman-detayi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Elektronik</a></li>
                                <li class="nav-item"><a routerLink="/departman-detayi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">İletişim</a></li>
                                <li class="nav-item"><a routerLink="/departman-detayi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Enerji</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/hakkimizda" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hakkımızda</a>
                        </li>
                        <li class="nav-item"><a routerLink="/projelerimiz" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Projelerimiz</a></li>
                        <li class="nav-item"><a routerLink="/iletisim" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">İletişim</a></li> -->
                    </ul>
                </div>
                <!-- <div class="nav-right">
                    <form>
                        <div class="input-group">
                            <input type="text" class="form-control search" placeholder="Search..." />
                        </div>

                        <button type="submit">
                            <i class="bx bx-search"></i>
                        </button>
                    </form>
                </div> -->
                <div class="nav-btn">
                    <a routerLink="/kariyer/16" class="box-btn">Kariyer</a>
                </div>
            </nav>
        </div>
    </div>
</div>