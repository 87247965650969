import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/departmentService';
import { NavbarService } from 'src/app/services/navbarService';
import { SiteSettingModel, SocialService } from 'src/app/services/socailService';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  departments: any;
  menus: any[] = [];
  languageId: number = 1;
  key: any;

  tel: SiteSettingModel;
  adres: SiteSettingModel;
  mail: SiteSettingModel;
  facebook: SiteSettingModel;
  instagram: SiteSettingModel;
  twitter: SiteSettingModel;
  youtube: SiteSettingModel;
  linkedin: SiteSettingModel;
  logoAlti: SiteSettingModel;
  footerText: SiteSettingModel; 

  constructor(private departmentService: DepartmentService, private socialService: SocialService, private navbarService: NavbarService,) { }

  ngOnInit(): void {
    this.departmentService.getDepartments().subscribe((gelenCevap: any) => {
      this.departments = gelenCevap
    })

    this.navbarService.getMenuByPosition("2").subscribe((gelenCevap: any) => {
      this.menus = gelenCevap
    })

    this.getPhone();
    this.getMail();

    this.socialService.getSocial(this.languageId, this.key = "Adres").subscribe((gelenCevap: any) => {
      this.adres = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "LinkedIn").subscribe((gelenCevap: any) => {
      this.linkedin = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Youtube").subscribe((gelenCevap: any) => {
      this.youtube = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Twitter").subscribe((gelenCevap: any) => {
      this.twitter = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Instagram").subscribe((gelenCevap: any) => {
      this.instagram = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "Facebook").subscribe((gelenCevap: any) => {
      this.facebook = gelenCevap
    })
    this.socialService.getSocial(this.languageId, this.key = "LogoAltı").subscribe((gelenCevap: any) => {
      this.logoAlti = gelenCevap
    })
     this.socialService.getSocial(this.languageId, this.key = "FooterText").subscribe((gelenCevap: any) => {
      this.footerText = gelenCevap
    })
  }


  getPhone() {
    this.socialService.getSocial(this.languageId, this.key = "Tel").subscribe((gelenCevap: any) => {
      this.tel = gelenCevap
    })
  }

  getMail() {
    this.socialService.getSocial(this.languageId, this.key = "Mail").subscribe((gelenCevap: any) => {
      this.mail = gelenCevap
    })
  }


}
