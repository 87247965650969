import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertifyService } from 'src/app/alertify.service';
import { ikService, result } from 'src/app/services/ikService';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
    ikForm!: FormGroup;
    message!: result;
    file: File = null;
    constructor(private formBuilder: FormBuilder, private alertify: AlertifyService, private ikService: ikService) { }

    ngOnInit(): void {
        this.createForm();
    }


    createForm() {
        this.ikForm = this.formBuilder.group({
            fullName: ['', Validators.required],
            email: ['', Validators.required],
            phone: ['', Validators.required],
            birthDate: ['', Validators.required],
            cvFile: ['', Validators.required],
            description: ['', Validators.required],
            recaptcha: ['', Validators.required]
        });
    }

    imageError: any;
    cardImageBase64: any;

    fileChangeEvent(event: any) {
        this.imageError = null;
        if (event.target.files && event.target.files[0]) {
            // Size Filter Bytes

            const max_size = 20971520;

            if (event.target.files[0].size > max_size) {
                this.imageError =
                    'Dosya boyutu en fazla' + max_size / 1000 + 'Mb olmalıdır';

                return false;
            }
                       
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const imgBase64Path = e.target.result;
                this.cardImageBase64 = imgBase64Path;
                this.ikForm.controls['cvFile'].setValue(this.cardImageBase64)

            };

            reader.readAsDataURL(event.target.files[0]);
            return "";
        }
        return
    }

    sendik() {
        if (this.ikForm.valid) {
            this.alertify.warning("Gönderiliyor...")
            this.ikService.sendik(this.ikForm.value).subscribe((resp) => {
                this.message = resp;
                if (this.message.success) {
                    this.alertify.success(this.message.message);
                    this.ikForm.reset();
                } else {
                    this.alertify.error(this.message.message);
                }
            });
        } else {
            this.alertify.error("Lütfen formu doldurun");
        }
    }
}
export interface ikModel {
    fullName: string;
    email: string;
    phone: string;
    birthDate: Date;
    cvFile: string;
    description: string;
}
