<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Proje(Adı) Detayları</h2>
            <ul>
                <li><a routerLink="/">Anasayfa </a></li>
                <li class="active">Proje Detayı</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="services-img mb">
                    <img src="assets/images/case-details/1.jpg" alt="Image">
                </div>
            </div>

            <div class="col-12">
                <div class="services-details-text">
                    <h2>BAŞLIK</h2>
                    <p>İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&.</p>
                    <p>İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&.</p>
                    <p>İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&.</p> <p>İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&.</p>
                </div>
            </div>
        </div>

        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="services-img mb-qc">
                        <img src="assets/images/solutions-details/5.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-8">
                    <h3>BAŞLIK</h3>
                    <p>İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&İçerik Yazısı Gelecek&&</p>
                </div>
            </div>
        </div>
    </div>
</section>