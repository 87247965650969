<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>404 Hata</h2>
            
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div class="error-area ptb-100">
    <div class="error-page">
        <img src="assets/images/404.gif" alt="error">
        <h3>Oops! Sayfa Bulunamadı!</h3>
        <p>Açmaya çalıştığınız sayfa bulunamadı. Lütfen daha sonra tekrar deneyiniz.</p>
        <a routerLink="/" class="box-btn">Anasayfaya dön</a>
    </div>
</div>