import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabsetComponent } from 'ngx-tabset';
import { delay } from 'rxjs/operators';
import { DepartmentModel, DepartmentService } from 'src/app/services/departmentService';
import { PageService } from 'src/app/services/pageService';
import { ProjectModel, ProjectService } from 'src/app/services/projectService';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit, AfterViewInit {
  @ViewChild('departmentTabs', { static: false }) memberTabs: TabsetComponent;

  items: ProjectModel[] = [];
  pageDetail: any;
  departments: DepartmentModel[] = [];
  baseUrl = environment.baseUrl;


  constructor(private http: HttpClient, private pageService: PageService,
    private projectService: ProjectService,
    private departmentService: DepartmentService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(param => {
      
      this.getPageDetail(param.pageId)
    }
    )
    this.departmentService.getDepartments().subscribe((gelenCevap: any) => {
      
      this.departments = gelenCevap.filter(x => x.projects.length > 0)
    })
    this.getAllPRojects()
    window.scrollBy(0, 400)

  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

    window.scrollBy(0, 500)
    window.scrollBy(300, 0)


  }


  getAllPRojects() {
    this.projectService.getProjectNonFilter().subscribe((gelenCevap: any) => {
      this.items = gelenCevap
    })
  }

  getPageDetail(id: string) {
    this.pageService.getPagesDetail(id).subscribe(resp => {
      this.pageDetail = resp;
    })
  }

  


}
