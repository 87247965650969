<section class="banner-area"> <!--Üst Sayfa -->
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Biliyoruz ki <br>Güçlü Fikirlerden<br>Lider Markalar Doğar</h1>
                            <p>Bilgi Çağının en önemli getirisi,teknolojinin hızının tecrübe kazanma süresini
                                kısaltmasıdır..</p>
                            <div class="banner-btn">
                                <a routerLink="/iletisim/15" class="box-btn">İletişime Geç</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img text-center">
                            <img src="assets/images/home.png" alt="banner-img" class="w-75" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/4.png" alt="shape" style="max-width: 200%;"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape" style="max-width: 200%;"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" style="max-width: 200%;"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" style="max-width: 200%;"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" style="max-width: 200%;"></div>
    </div>
</section>

<section class="home-service-area pt-100 pb-70"> <!--Departmanlar -->
    <div class="container">
        <div class="section-title">
            <!-- <span>Smart Services</span> -->
            <h2>Departmanlarımız</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6" *ngFor="let department of departments">
                <div class="single-service">
                    <div class="service-img">
                        <img src="{{baseUrl}}/WebImages/Departments/{{department.image}}" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>{{department.name}}</h3>
                        <p>{{department.description}}</p>
                        <a routerLink="/page/{{department.slug}}/{{department.order}}" class="line-bnt">Daha Fazlası</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area bg-color" *ngIf="hakkimizda"><!--Hakkımızda -->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Hakkımızda</h2>
                        <p>{{hakkimizda.content}}</p>
                    </div>
                    <a routerLink="/hakkimizda/{{hakkimizda.id}}" class="box-btn">Daha Fazlası</a><!-- HATA-->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-area bg-color ptb-100"><!--Çözümlerimiz -->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Çözümlerimiz</h2>
                        <p>Biliyoruz ki güçlü fikirlerden lider markalar doğar bunun için efektif çözümler üretiyoruz.
                        </p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i>Web & Mobil Programlama</li>
                        <li><i class="flaticon-correct"></i>E-Ticaret Hizmetleri</li>
                        <li><i class="flaticon-correct"></i>Sosyal Medya Çalışmaları</li>
                        <li><i class="flaticon-correct"></i>Grafik Tasarım</li>
                        <li><i class="flaticon-correct"></i>Dil Kursları</li>
                    </ul>
                    <a routerLink="/iletisim/15" class="box-btn">İletişime Geçin</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-team-area ptb-100"> <!--Slider -->
    <div class="container">
        <div class="section-title">
            <h2>Çözüm Ortaklarımız</h2>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide *ngFor="let item of partners">
                    <div class="single-team" style="text-align: center;">
                        <div class="team-img">
                            <a href="{{item.link}}"><img src="{{baseUrl}}/WebImages/Galleries/{{item.imagePath}}"
                                    alt="team" /></a>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>