<div class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngIf="pageDetail">
            <h2>{{pageDetail.title}}</h2>
            <ul>
                <li><a routerLink="/">Anasayfa </a></li>
                <li class="active">{{pageDetail.title}}</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="services-details-area ptb-100 home-company-area  " *ngIf="pageDetail">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="services-details-text"  *ngIf="pageDetail">
                    <h1 innerHTML="{{pageDetail.title}}"></h1>
                    <p id="paragraphText" innerHTML="{{pageDetail.content}}"></p>
                    <h2><br></h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-img" *ngIf="pageDetail.bannerImage != null">
                    <img src="{{baseUrl}}/WebImages/Pages/{{pageDetail.bannerImage}}" alt="Image">
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-bottom: 3rem;">
        <div class="section-title" style="margin-top: 1rem;">
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide *ngFor="let item of items">
                    <div class="single-team" style="">
                        <div class="team-img w-75 text-center m-auto" style="text-align: center; ">
                            <img src="{{baseUrl}}/WebImages/Projects/{{item.image}}" alt="team" />
                            <h4>{{item.title}}</h4>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>