<div class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngIf="pageDetail">
            <h2>{{pageDetail.title}}</h2>
            <ul>
                <li><a routerLink="/">Anasayfa </a></li>
                <li class="active">{{pageDetail.title}}</li>
            </ul>
        </div>
    </div>


    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-case pt-100 pb-70"> <!-- Projeler -->
    <div class="container">
        <div class="case" >
            <!-- <ngx-tabset #departmentTabs>
                <ngx-tab *ngFor="let type of departments; let i =index" tabTitle="{{type.name}}" [disabled]="false" [active]="i==0 ? true:false">
                    <div class="row case-list" >
                        <div class="col-lg-3 col-sm-12" *ngFor="let item of type.projects" style="margin-bottom: 20px;">
                            <div class="single-case" style="height: 100%;">
                                <div class="case-img" *ngIf="item.image != null" style="height: 75%;" >
                                    <a><img src="{{baseUrl}}/WebImages/Projects/{{item.image}}" alt="case"/></a>
                                </div>            
                                <div class="content" style="height: 25%; text-align: center;" >
                                    <a><h3>{{item.title}}</h3></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset> -->

            <ngx-tabset customNavClass="nav-test-class" customTabsClass="container">
                <ngx-tab *ngFor="let type of departments; let i=index" tabTitle="{{type.name}}" [active]="i==0 ? true:false">
                    <div class="row case-list" >
                        <div class="col-lg-3  col-sm-12" *ngFor="let item of type.projects" style="margin-bottom: 20px; ">
                            <div class="single-case" style="max-width:100%; height: 100%;">
                                <div class="case-img" *ngIf="item.image != null" style="height: 75%; text-align: center;" >
                                    <a><img src="{{baseUrl}}/WebImages/Projects/{{item.image}}"alt="case"/></a>
                                </div>            
                                <div class="content" style="height: 25%; text-align: center;" >
                                    <a><h3>{{item.title}}</h3></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
               <ngx-tab tabTitle="" tabSubTitle="" [disabled]="true"></ngx-tab>
              </ngx-tabset>
        </div>
    </div>
</section>