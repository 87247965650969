import { Component, OnInit } from '@angular/core';
import { CertificateService } from 'src/app/services/certificateService';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  certificates:any[]=[]

  constructor(private certificateService:CertificateService) { }

  ngOnInit(): void {
    this.certificateService.getCertificate().subscribe((gelenCevap1:any)=>{
      this.certificates=gelenCevap1
  })
  }

}
